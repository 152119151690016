// 依赖:
//   - flowbite/Dismiss
// 前提:
//   - app/views/partials/components/_toast.html* (建议放画面顶部，且 toast box 的 style 适当调整)
// 使用: 
//  import { Toast } from '..../packs-js/components/toast.ts'; // 单独引入
//  new Toast('success', 'Hello World'); // 默认自动关闭
//  new Toast('error', 'Hello World', {autoClose: false}); // 不自动关闭

import {Dismiss} from 'flowbite';

// Toast ****** START
export class Toast {
  // Class constants/variable *** START ***
  static DEBUG: boolean = false; // 调试开关，打开输出额外 console log
  static INS_NAME: string = 'toast';
  static AUTO_CLOSE: boolean = true; // 默认自动关闭
  static AUTO_CLOSE_TIME: number = 3000; // 默认自动关闭时间
  static DOM_ID_PREFIX: string = 'toast';
  static ICON_CLASS: any = {
    success: 'icon-right-cricle text-[#04A877]',
    error: 'icon-attention text-[#EF4444]',
    // info: 'icon-info',
    // warning: 'icon-warning',
  };
  // 用于设置背景颜色
  static BG_CLASS: any = {
    success: 'bg-[#DEF7EC]',
    error: 'bg-[#FDF2F2]'
  };
  static TOAST_ATTR_MAP = {
    sample: 'data-toast-sample',
    icon: 'data-toast-icon',
    close: 'data-toast-close',
    content: 'data-toast-content',
  };
  static BOX_ID: string = 'toast-box';
  static PAGE_HEADER_ID: string = 'page-header';
  static DISMISS_ATTR: string = 'data-dismiss-target';
  static CONTENT_ATTR: string = 'data-toast-content';

  // Instance constants/variable *** START ***
  debug_flg: boolean; // 默认非调试
  classObj: any; // 类对象，内部使用
  boxObj: any;
  domObj: any;
  domId: string;
  autoClose: boolean; // 默认不关闭

  // Initialization function
  constructor(type: string, content: string, options = {}) {
    const ins = this;
    ins.classObj = Object.getPrototypeOf(this).constructor;
    const funcName = `[${ins.classObj.INS_NAME}]`;
    ins.debug_flg = ins.classObj.DEBUG;
    if (ins.debug_flg) console.debug(funcName, `type: ${type}, content: ${content}, options: `, options);

    // 自动关闭标识
    if (typeof(options['autoClose']) === 'boolean') {
      ins.autoClose = options['autoClose'];
    } else {
      ins.autoClose = ins.classObj.AUTO_CLOSE;
    }
    // 新建 Toast ID
    ins.domId = `${ins.classObj.DOM_ID_PREFIX}-${new Date().getTime()}`;
    // 根据 type 选择 icon class
    const iconClass = ins.classObj.ICON_CLASS[type] || ins.classObj.ICON_CLASS.success;

    if (ins.debug_flg) console.debug(funcName, `ins.domId: ${ins.domId}, iconClass: ${iconClass}, ins.autoClose: ${ins.autoClose}`);
    // 定位 Toast Box
    ins.boxObj = document.querySelector(`#${ins.classObj.BOX_ID}`);
    if (ins.boxObj == undefined) {
      if (ins.debug_flg) console.debug(funcName, `Not found DOM[${ins.classObj.BOX_ID}]`);
      return;
    }
    // 获取 Toast Sample DOM
    const sampleAttr = ins.classObj.TOAST_ATTR_MAP.sample;
    const sampleObj = document.querySelector(`[${sampleAttr}]`);
    if (sampleObj == undefined) {
      console.error(funcName, `Not found DOM[${sampleAttr}]`);
      return;
    }
    // 拷贝 Toast Sample DOM，并设置 ID，附加到 Box DOM
    ins.domObj = sampleObj.cloneNode(true);
    if (ins.domObj == undefined) {
      console.error(funcName, 'Not found DOM[toast-item]');
      return;
    }
    ins.domObj.removeAttribute(`${sampleAttr}`);
    ins.domObj.id = ins.domId;
    ins.boxObj.appendChild(ins.domObj);
    // 设置 Toast icon 样式
    const iconSelector = `#${ins.domId} [${ins.classObj.TOAST_ATTR_MAP.icon}]`;
    const iconObj = document.querySelector(iconSelector);
    if (iconObj) {
      const _iconClass = iconObj.getAttribute('class') || '';
      iconObj.setAttribute('class', `${_iconClass} ${iconClass}`);
    }
    // 设置 Toast content 内容
    const contentSelector = `#${ins.domId} [${ins.classObj.TOAST_ATTR_MAP.content}]`;
    const contentObj = document.querySelector(contentSelector);
    // console.debug(funcName, `contentObj[${contentSelector}]: ${contentObj}`);
    if (contentObj) {
      contentObj.innerHTML = content;
    }
    ins.bindToastCloseBtn(); // 绑定 Toast 内部 close 按钮事件
    ins.show(); // 显示 Toast
    // 自动关闭触发
    if (ins.autoClose) {
      setTimeout(() => {
        ins.hide();
      }, ins.classObj.AUTO_CLOSE_TIME);
    }
    // 根据 type 选择 background-color class
    const bgClass = ins.classObj.BG_CLASS[type] || ins.classObj.BG_CLASS.success;
    const _bgClass = ins.domObj.getAttribute('class') || '';
    ins.domObj.setAttribute('class', `${_bgClass} ${bgClass}`);
    // 根据共通的page_header来获取屏幕顶部和右侧的距离
    const pageHeaderObj = document.querySelector(`#${ins.classObj.PAGE_HEADER_ID}`);
    // 判断是否能获取到元素
    if (pageHeaderObj == undefined) {
      console.error(funcName, `Not found DOM[${ins.classObj.PAGE_HEADER_ID}]`);
      return;
    }
    // 获取pageHeaderObj右边框距离右侧的距离 和 底部距离顶部的距离
    const rect = pageHeaderObj.getBoundingClientRect();
    // 获取右侧padding样式的距离
    const style = getComputedStyle(pageHeaderObj).paddingRight;
    const paddingRight = parseFloat(style);
    // 距离右侧大小
    const distanceFromRight = rect.left + paddingRight;
    // 距离顶部大小
    const distanceFromTop = rect.bottom + 4;
    // 设置弹窗距离顶部和右侧的margin样式
    ins.boxObj.style.marginRight = `${distanceFromRight}px`;
    ins.boxObj.style.marginTop = `${distanceFromTop}px`;
  }

  // 绑定 Toast close 事件
  bindToastCloseBtn(): void {
    const ins = this;
    const closeAttr = ins.classObj.TOAST_ATTR_MAP.close
    const closeBtn = document.querySelector(`#${ins.domId} [${closeAttr}]`);
    if (this.domObj && closeBtn) {
      // console.log(this);
      closeBtn.setAttribute(ins.classObj.DISMISS_ATTR, `#${ins.domId}`);
      // new Dismiss(this.domObj, closeBtn); // Dismiss方法会影响其他Flowbite组件的使用，避免使用
      closeBtn.addEventListener('click', function() {
        ins.hide();
      });
      closeBtn.removeAttribute(closeAttr);
    } else {
      console.error('The dismiss element with id "'.concat(this.domId, '" does not exist. Please check the data-dismiss-target attribute.'));
    }
  }
  // 判断 Toast box/DOM 不存在
  invalidDom(opt: any): boolean {
    const funcName = opt['funcName'] || `[${this.classObj.INS_NAME}.bindCloseBtn]`;
    if (this.boxObj == undefined) {
      console.error(funcName, 'Not found DOM[toast-box]');
      return true;
    }
    if (this.domObj == undefined) {
      console.error(funcName, 'Not found DOM[toast-item]');
      return true;
    }
    return false;
  }
  // 显示 Toast
  show(): void {
    const funcName = `[${this.classObj.INS_NAME}.show]`;
    if (this.invalidDom({funcName: funcName})) return;
    if (this.debug_flg) console.debug(funcName, `domObj[${this.domId}] show`);

    this.domObj.classList.remove('hidden');
  }
  // 隐藏 Toast
  hide(): void {
    const funcName = `[${this.classObj.INS_NAME}.show]`;
    if (this.invalidDom({funcName: funcName})) return;
    if (this.debug_flg) console.debug(funcName, `domObj[${this.domId}] hide`);

    const closeBtn = document.querySelector(`[${this.classObj.DISMISS_ATTR}='#${this.domId}']`);
    if (closeBtn) {
      // conlog.debug(funcName, 'closeBtn click');
      closeBtn.click();
    } else {
      // conlog.debug(funcName, 'domObj class add hidden');
      this.domObj.classList.add('hidden');
    }
    // 防止 DISMISS 无效果，延时删除 Toast DOM
    setTimeout(() => {
      this.boxObj.removeChild(this.domObj);
    }, 1500);
  }
    
}
// Toast ****** END
