import {MstCom} from '../packs-js/common/_all.js';
import { Toast } from '../packs-js/components.ts';

$('.bookmarkButton').on('click', function () {
    var button = $(this); // Capture the button reference

    var isLogin = button.attr('data-is-login')
    var bookmark = button.attr('data-bookmark-class')
    var bookmark_active = bookmark + '_active'
    // 判断是否有 bookmark1_active 这个class
    var isBookmarked = button.hasClass(bookmark_active);
    // 操作显示的文本内容
    var collectText = isBookmarked ? TEXT.dialogue.application.cancel_collected : TEXT.dialogue.application.collect

    if (isLogin === "true") {
        var icon = button.find('#bookmarkIcon');
        var text = button.find('#bookmarkText');
        var textSpan = text.find('.text-sm'); // Get the text span element

        // 存在 bookmark1_active 则设置取消收藏需要的参数
        textSpan.text(collectText);
        var add_collect = isBookmarked ? "false" : "true";
        var controller = button.attr('data-controller')
        var collectItem = button.attr('data-collect-item')
        var src = isBookmarked ? button.attr('data-unstar-path') : button.attr('data-star-path');

        const formData = new FormData();
        formData.append('collect_item', collectItem);
        formData.append('add_collect', add_collect);

        // 改变收藏状态
        // 传入参数： model_name => 要修改的表model名， collect_item => 具体的收藏的code或者id，add_collect => 用于判断收藏还输取消收藏
        let quarterChartUrl = "/" + controller + "/change_collect_status"
        MstCom.fetchJson(quarterChartUrl, {
            method: 'POST', data: formData, success: function (jsonData) {
                // Show the text briefly and then hide it
                text.removeClass('hidden');
                setTimeout(function () {
                    text.addClass('hidden');

                    // Toggle icon and active class after the text has been hidden
                    icon.attr('src', src)  // Adjust paths as per your asset structure

                    button.toggleClass(bookmark_active);
                    button.toggleClass(bookmark);
                    // 设置flash弹窗
                    let flashType = TEXT.dialogue.success;
                    let flashContext = collectText + TEXT.dialogue.success;
                    new Toast(flashType, flashContext);
                }, 1000); // Change icon and class after text hides

                // 更改相同概念按钮的样式
                document.querySelectorAll(".collect_item").forEach(function($collectEl ) {
                    var collectItemOther = $collectEl.getAttribute("data-collect-item");
                    var showText = $collectEl.querySelector('#showText');
                    var iconOther = $collectEl.querySelector('#bookmarkIcon') as HTMLImageElement;
                    var srcOther = isBookmarked ? $collectEl.getAttribute('data-unstar-path') : $collectEl.getAttribute('data-star-path');
                    if (collectItemOther === collectItem){
                        // 更改按钮的样式
                        if (isBookmarked){
                            // 进行取消收藏的样式设定
                            if (showText) {
                                showText.textContent = TEXT.dialogue.application.collect;
                            }
                            if (iconOther) {
                                iconOther.src = srcOther;
                            }

                        } else {
                            // 进行收藏的样式设定
                            if (showText) {
                                showText.textContent = TEXT.dialogue.application.collected;
                            }
                            if (iconOther) {
                                iconOther.src = srcOther;
                            }
                        }

                        $collectEl.classList.toggle(bookmark_active);
                        $collectEl.classList.toggle(bookmark);
                    }
                });
            }, debug: false
        })
    } else {
        // 跳转到登录页面 附带登录后重定向路径
        window.location.href = '/user/login?return_to=' + window.location.pathname;
//        // 设置flash弹窗
//        let flashType = TEXT.toast.type.error;
//        let flashContext = collectText + TEXT.dialogue.error;
//        new Toast(flashType, flashContext);
    }
});


$('.collect_item').on('click', function () {
    var button = $(this); // Capture the button reference
    var bookmarkText = $(this).find('#bookmarkText');

    // 判断是否有 bookmark1_active 这个class
    var isBookmarked = button.hasClass('bookmark2_active');
    // 获取图片显示标签
    var icon = $(this).find('#bookmarkIcon');
    // 获取应该要设置成的星号图片src
    var src = isBookmarked ? button.attr('data-unstar-path') : button.attr('data-star-path');
    // 判断是否登录
    var isLogin = button.attr('data-is-login')

    // 操作显示的文本内容
    var collectText = isBookmarked ? TEXT.dialogue.application.cancel_collected : TEXT.dialogue.application.collect
    if (isLogin === "true") {
        // 获取需要进行收藏的对象
        var collectItem = button.attr('data-collect-item')
        // 判断是进行收藏还是取消收藏
        var addCollect = isBookmarked ? "false" : "true";
        const formData = new FormData();
        formData.append('collect_item', collectItem);
        formData.append('add_collect', addCollect);

        // 改变收藏状态
        // 传入参数： model_name => 要修改的表model名， collect_item => 具体的收藏的code或者id，add_collect => 用于判断收藏还输取消收藏
        let quarterChartUrl = "/theme/change_collect_status"
        MstCom.fetchJson(quarterChartUrl, {
            method: 'POST', data: formData, success: function (jsonData) {
                // 更改按钮的样式
                icon.attr('src', src)
                button.toggleClass('bookmark2');
                button.toggleClass('bookmark2_active');

                if (isBookmarked){
                    bookmarkText.text(TEXT.dialogue.application.collect);
                } else {
                    bookmarkText.text(TEXT.dialogue.application.collected);
                }
                // 设置flash弹窗
                let flashType = TEXT.toast.type.success;
                let flashContext = collectText + TEXT.dialogue.success;
                new Toast(flashType, flashContext);
                // 更改相同概念按钮的样式
                document.querySelectorAll(".bookmarkButton").forEach(function($collectEl) {
                    var collectItemOther = $collectEl.getAttribute("data-collect-item");
                    var text = $collectEl.querySelector('#bookmarkText');
                    var iconOther = $collectEl.querySelector('#bookmarkIcon') as HTMLImageElement;

                    var textSpan = text.querySelector('.text-sm'); // Get the text span element

                    // 存在 bookmark1_active 则设置取消收藏需要的参数
                    textSpan.textContent = collectText;

                    if (collectItemOther === collectItem){
                        // Show the text briefly and then hide it
                        text.classList.remove('hidden');
                        setTimeout(function () {
                            text.classList.add('hidden');

                            // Toggle icon and active class after the text has been hidden
                            iconOther.src = src;
                            $collectEl.classList.toggle('bookmark2_active');
                            $collectEl.classList.toggle('bookmark2');
                        }, 1000); // Change icon and class after text hides
                    }
                });

            }, debug: false
        })
    } else {
        // 跳转到登录页面 附带登录后重定向路径
        window.location.href = '/user/login?return_to=' + window.location.pathname;
//        // 设置flash弹窗
//        let flashType = TEXT.toast.type.error;
//        let flashContext = collectText + TEXT.dialogue.error;
//        new Toast(flashType, flashContext);
    }
})